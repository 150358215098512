import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import axios from 'axios'
import cookie from 'react-cookies'
import { RRule, SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import SurveyImageTask from './surveys/SurveyImageTask'
import SurveyTextTask from './surveys/SurveyTextTask'
import SurveyNumberTask from './surveys/SurveyNumberTask'
import SurveyComponentMultiple from './surveys/SurveyComponentMultiple'
import SurveyComponentUnique from './surveys/SurveyComponentUnique'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const SurveyTask = ({
  setCloningTask,
  cloningTask,
  taskData,
  readyToCollect,
  formDataCollector,
  setSaving,
  isStep3Completed,
  setIsStep3Completed,
  isRequired: parentIsRequired
}) => {
  const [description, setDescription] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [brand, setBrand] = useState('')
  const [category, setCategory] = useState('')
  const [productSelection, setProductSelection] = useState('')
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [undefinedEndTime, setUndefinedEndTime] = useState(true)
  const [recurrenceSelection, setRecurrenceSelection] = useState('')
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [recurrenceWeekDaysSelection, setRecurrenceWeekDaysSelection] = useState([])
  const [productsBase, setProductsBase] = useState({})
  const [productCount, setProductCount] = useState('...')
  const [unitOfSale, setUnitOfSale] = useState('')

  const [selectedOption, setSelectedOption] = useState('')
  const [surveyInstances, setSurveyInstances] = useState([])
  const [questionOrder, setQuestionOrder] = useState([])
  const [isSelectionValid, setIsSelectionValid] = useState(true)
  const [isRequired, setLocalRequired] = useState(parentIsRequired)
  const [showImageWarning, setShowImageWarning] = useState(false)

  const surveySelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'text', text: 'Texto' },
    { value: 'number', text: 'Números' },
    { value: 'multiplerb', text: 'Opción única' },
    { value: 'multiplechk', text: 'Selección múltiple' },
    { value: 'image', text: 'Subir imagen' }
  ]
  const recurrenceSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'daily', text: 'Todos los días' },
    { value: 'weekly', text: 'Semanal' }
  ]

  const recurrenceWeekDays = [
    { rrlue: RRule.MO, value: 'Lunes', text: 'L' },
    { rrlue: RRule.TU, value: 'Martes', text: 'M' },
    { rrlue: RRule.WE, value: 'Miércoles', text: 'M' },
    { rrlue: RRule.TH, value: 'Jueves', text: 'J' },
    { rrlue: RRule.FR, value: 'Viernes', text: 'V' },
    { rrlue: RRule.SA, value: 'Sábado', text: 'S' },
    { rrlue: RRule.SU, value: 'Domingo', text: 'D' }
  ]

   ////// Stops fetchs when page is changed
   const abortController = new AbortController()
   var abortClientController = new AbortController()
   const abortCurrentFetchs = () => {
     abortClientController.abort();
     abortController.abort();
   };
   window.addEventListener("beforeunload", abortCurrentFetchs);
   //////

   // Stops client fetch when is unfocus
   const abortClientFetchs = () => {
    abortClientController.abort();
   };
   //

  const onChangeRadios = (event) => {
    if (event.target.value === 'true') {
      setUndefinedEndTime(true)
      setEndTime(null)
    } else {
      setUndefinedEndTime(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      increaseRecurrenceInterval()
    } else if (e.keyCode === 40) {
      decreaseRecurrenceInterval()
    }
  }

  const handleToggleDay = (day) => {
    const dayVal = day?.value
    const recurrenceWeekDaySelection = recurrenceWeekDays.find((day) => day.value === dayVal)
    if (!recurrenceWeekDaySelection) {
      return
    }

    const index = recurrenceWeekDaysSelection.findIndex(
      (day) => day.value === recurrenceWeekDaySelection.value
    )
    if (index === -1) {
      setRecurrenceWeekDaysSelection([...recurrenceWeekDaysSelection, recurrenceWeekDaySelection])
    } else {
      let arr = [...recurrenceWeekDaysSelection]
      arr.splice(index, 1)
      setRecurrenceWeekDaysSelection(arr)
    }
  }

  const onChangeRecurrenceInterval = (event) => {
    let strNum = event?.target?.value
    if (!strNum) {
      setRecurrenceInterval(1)
      return
    }
    if (strNum) {
      let num = Number(strNum)
      if (isNaN(num)) return
      if (num < 1) num = 1
      if (num > 99) num = 99
      setRecurrenceInterval(num)
    }
  }

  const handleRecurrenceTypeChange = (event) => {
    if (event?.target?.value) {
      setRecurrenceSelection(event?.target?.value)
    }
  }

  const handleStartTimeChange = (event) => {
    if (event?.target?.value) {
      setStartTime(event?.target?.value)
    }
  }

  const handleEndTimeChange = (event) => {
    if (event?.target?.value) {
      setEndTime(event?.target?.value)
    }
  }

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event.map((e) => e.value))
    }
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleOptionValid = (event) => {
    const selectedValue = event.target.value
    setSelectedOption(selectedValue)
    setIsSelectionValid(selectedValue !== '')
    if (selectedValue === 'image' && hasImageType()) {
      setShowImageWarning(true)
    } else {
      setShowImageWarning(false)
    }
  }

  const isClientOrSomeQualifierSelected = () => {
    if (clients?.length > 0 || isSomeQualifierSelected()) {
      return true
    }
    return false
  }

  const isSomeQualifierSelected = () => {
    if (qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0) {
      return true
    }
    return false
  }

  const noAvailableQualifiers = () => {
    if (
      (!qualifierAOptions?.length || qualifierAOptions?.length === 0) &&
      (!qualifierBOptions?.length || qualifierBOptions?.length === 0) &&
      (!qualifierCOptions?.length || qualifierCOptions?.length === 0)
    ) {
      return true
    }
    return false
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
  }

  const resolveEndTime = () => {
    if (undefinedEndTime) {
      return (
        <>
          de forma <span className="highlight-blue">indefinida</span>
        </>
      )
    }
    if (endTime != '') {
      return (
        <>
          hasta el <span className="highlight-blue">{getFormattedDateOnly(endTime)}</span>
        </>
      )
    }
  }

  const increaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval + 1 } })
  }

  const decreaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval - 1 } })
  }

  const getFormattedDateOnly = (date) => {
    const d = new Date(fixDateString(date))
    return d.toLocaleDateString()
  }

  const buildRecurrence = () => {
    let rule
    switch (recurrenceSelection) {
      case 'daily':
        rule = new RRule({
          freq: RRule.DAILY
        })
        break
      case 'weekly':
        rule = new RRule({
          freq: RRule.WEEKLY,
          interval: recurrenceInterval,
          byweekday: recurrenceWeekDaysSelection.map((day) => day.rrlue)
        })
        break
    }
    return rule
  }

  const buildRecurrenceMessage = () => {
    let rule = buildRecurrence()
    if (rule && startTime) {
      return (
        <>
          Esta tarea se ejecutará <span className="highlight-blue">{rule.toText(SPANISH)}</span> a
          partir del <span className="highlight-blue">{getFormattedDateOnly(startTime)}</span>{' '}
          {resolveEndTime()}
        </>
      )
    }
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3){
        abortClientController = new AbortController()
        return fetch(`${BACKEND_URL}/businesses/1/clients?q=${inputValue}&active=true`, {
          method: 'GET',
          signal: abortClientController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
        .then((response) => 
          response.json()
        ) 
        .then((data) => 
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.name} - ${cl.db_ref}`,
            client: cl
          }))
        )
        .catch((error) => {
          if(error.name != 'AbortError'){
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const getQualifiers = () => {
    fetch(BACKEND_URL + '/promo_qualifiers', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result)
      })
      .catch((e) => console.log(e))
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    if (_qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0) {
      return 'success'
    }
    return null
  }

  const getProductsBaseData = () => {
    fetch(BACKEND_URL + '/businesses/1/products/promo_necessary_data', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions = qualifiers?.qualifier_2.map((value) => ({
    value: value,
    label: value,
    qualifier_2: value
  }))

  const qualifierBOptions = qualifiers?.qualifier_3.map((value) => ({
    value: value,
    label: value,
    qualifier_3: value
  }))

  const qualifierCOptions = qualifiers?.qualifier_4.map((value) => ({
    value: value,
    label: value,
    qualifier_4: value
  }))

  const handleClientOption = (options) => {
    setClients(options)
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const collectData = () => {
    let rule = buildRecurrence()
    let jsonData = {
      description: description,
      recurrence_format: rule.toString(),
      recurrence_interval: recurrenceInterval,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(startTime),
      end_date: fixDateString(endTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      products_selection_type: productSelection,
      category_db_ref: category?.db_ref,
      category_name: category?.description,
      brand_db_ref: brand?.db_ref,
      brand_name: brand?.name,
      products_db_ref: products.map((p) => p.db_ref).sort(),
      product_names: products.map((p) => p.db_ref + ' ' + p.name).sort(),
      unit_of_sale: unitOfSale,
      task_quantity: Object.values(surveyInstances).length,
      surveys: Object.values(surveyInstances).map((instance) => ({
        uid: crypto.randomUUID(),
        type: instance.type,
        id: instance.id,
        type: instance.type,
        question: instance.question,
        descriptions: instance.descriptions,
        options: instance.options,
        response: ''
      }))
    }
    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      setCloningTask(true)
      setDescription(taskData.description)

      if (taskData.client_names?.length > 0) {
        setClientOrAttribute('client')
        let _clients = []
        for (const clientName of taskData.qualifier_1) {
          let c = await getClient(clientName)
          if (c?.length > 0) {
            _clients.push(c[0])
          }
        }
        setClients(_clients)
      } else {
        setClientOrAttribute('qualifier')
        if (qualifierAOptions?.length > 0) {
          setQualifierA(taskData.qualifier_2)
        }
        if (qualifierBOptions?.length > 0) {
          setQualifierB(taskData.qualifier_3)
        }
        if (qualifierCOptions?.length > 0) {
          setQualifierC(taskData.qualifier_4)
        }
      }

      switch (taskData.products_selection_type) {
        case 'individual_products':
          {
            setProductSelection('individual_products')
            let _products = []
            for (const productDbRef of taskData.products_db_ref) {
              let p = await getProducts(productDbRef)
              if (p?.length > 0) {
                _products.push(p[0]?.product)
              }
            }
            let cleanProducts = _products.filter(
              (value, index, self) => index === self.findIndex((t) => t.db_ref === value.db_ref)
            )
            setProducts(cleanProducts)
          }
          break
        case 'brand':
          {
            setProductSelection('brand')
            let _brand = productsBase?.brand?.find((b) => b.db_ref === taskData.brand_db_ref)
            setBrand(_brand)
          }
          break
        case 'category':
          {
            setProductSelection('category')
            let _category = productsBase?.categories?.find(
              (c) => c.db_ref === taskData.category_db_ref
            )
            setCategory(_category)
          }
          break
      }
      setUnitOfSale(taskData.unit_of_sale)
      if (taskData.surveys?.length > 0) {
        setLocalRequired(false)
        const clonedSurveyInstances = taskData.surveys.map((surveyInstance) => {
          return { ...surveyInstance }
        })
        setSurveyInstances(clonedSurveyInstances)
      }
      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  const handleUpdateQuestion = (id, question, value) => {
    setSurveyInstances((prevInstances) => ({
      ...prevInstances,
      [id]: {
        ...prevInstances[id],
        [question]: value
      }
    }))
  }

  const generateOptions = (type) => {
    switch (type) {
      case 'multiplechk':
        return [
          { id: 1, text: 'Opción 1', selected: false, editable: true },
          { id: 2, text: 'Opción 2', selected: false, editable: true }
        ]
      case 'multiplerb':
        return [
          { id: 1, text: 'Opción 1', selected: false, editable: true },
          { id: 2, text: 'Opción 2', selected: false, editable: true }
        ]
      default:
        return []
    }
  }
  const handleClickSelectSurvey = () => {
    const isSelectionEmpty = selectedOption.trim() === ''
    setLocalRequired(isSelectionEmpty)

    if (selectedOption === 'image' && hasImageType()) {
      setShowImageWarning(true)
      return
    } else {
      setShowImageWarning(false)
    }

    if (isSelectionValid && !isSelectionEmpty) {
      const lastId =
        Object.keys(surveyInstances).length > 0 ? Math.max(...Object.keys(surveyInstances)) : 0
      const newInstanceId = lastId + 1
      setSurveyInstances((prevInstances) => ({
        ...prevInstances,
        [newInstanceId]: {
          type: selectedOption,
          options: generateOptions(selectedOption),
          response: ''
        }
      }))
      setSelectedOption('')
    } else {
      setTimeout(() => {
        setLocalRequired(false)
      }, 2000)
    }
  }
  const duplicateSurvey = (instanceId, duplicatedData, previousData) => {
    const newId =
      Object.keys(surveyInstances).length > 0
        ? Math.max(...Object.keys(surveyInstances).map(Number)) + 1
        : 1
    const duplicatedSurvey = { ...previousData, ...duplicatedData }
    setSurveyInstances((prevInstances) => ({
      ...prevInstances,
      [newId]: duplicatedSurvey
    }))
  }

  const deleteSurvey = (id) => {
    const { [id]: deletedInstance, ...restInstances } = surveyInstances
    setSurveyInstances(restInstances)
    if (deletedInstance.type === 'image') {
      setShowImageWarning(false)
    }
  }

  const hasImageType = () => {
    return Object.values(surveyInstances).some((instance) => instance.type === 'image')
  }

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  useEffect(() => {
    getQualifiers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData, productsBase.brand, productsBase.categories])

  useEffect(() => {
    const isEmptySurveyInstances = Object.keys(surveyInstances).length === 0
    setLocalRequired(isEmptySurveyInstances ? true : parentIsRequired)
  }, [surveyInstances, parentIsRequired])

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = Object.entries(surveyInstances)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const newSurveyInstances = {}
    items.forEach(([key, value], index) => {
      newSurveyInstances[index + 1] = value
    })

    setSurveyInstances(newSurveyInstances)
  }

  useEffect(() => {}, [surveyInstances])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>
            PASO 2: Escriba una descripción de la tarea que será visible para sus colaboradores en
            la aplicación
          </b>
        </div>
        <hr className="hr-promos-form" />
        <div className="form-group form-control-width">
          <input
            type="text"
            maxLength="40"
            className="form-control"
            id="description"
            name="description"
            onChange={handleDescriptionChange}
            value={description}
            placeholder="Ejemplo Tarea marca Ubiqua"
            required
          />
          <p className="small text-muted m-0 pull-right">
            Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
          </p>
        </div>
      </div>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>PASO 3: Escoja a qué clientes se debe aplicar esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por cliente individual o Atributo(s)</p>

        <div>
          <div className="form-control-width">
            <select
              onChange={handleClientOrAttributeChange}
              className={`select-appliesType form-control-width ${
                description === '' && 'inactive'
              }`}
              name="applies_target_to_type"
              value={clientOrAttribute}
              disabled={description === ''}
              required
            >
              <option value="" hidden>
                Escoja una opción
              </option>
              <option value="client">Cliente individual</option>
              {!noAvailableQualifiers() && <option value="qualifier">Atributo(s)</option>}
            </select>
          </div>
        </div>
      </div>

      {clientOrAttribute === 'client' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 3.1: Escoja los clientes que aplican para esta tarea</b>
            </p>
          </div>
          <div className="fake-validator-container" id='clients'>
            <input
              className="input-fake-validator"
              type="text"
              required
              value={clients.length >= 1 ? clients : null}
              
            />
            <AsyncSelect
              className="clients"
              placeholder={'Buscar cliente por nombre o código'}
              noOptionsMessage={() => 'No hubo resultados'}
              loadingMessage={() => 'Cargando clientes...'}
              name="selectOptionClients"
              loadOptions={getClient}
              isClearable={true}
              searchParamName={'q'}
              isSearchable={true}
              isMulti
              value={clients}
              onChange={(e) => handleClientOption(e)}
              onBlur={abortClientFetchs}
            />
          </div>
        </div>
      )}

      {clientOrAttribute === 'qualifier' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <b>
              PASO 3.1: Escoja los atributos que deben de tener los clientes para activar esta
              tarea. Mínimo debe de escoger 1 atributo
            </b>
          </div>
          <div className="qualifier-title">Atributo A</div>
          <div className="fake-validator-container" style={{ zIndex: 3 }}>
            {qualifierAOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierAOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierA(e)}
              options={qualifierAOptions}
              value={qualifierA?.map((q) => ({ value: q, label: q }))}
              z
            />
          </div>
          <div className="qualifier-title">Atributo B</div>
          <div className="fake-validator-container" style={{ zIndex: 2 }}>
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierBOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierB(e)}
              options={qualifierBOptions}
              value={qualifierB?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo C</div>
          <div className="fake-validator-container">
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierCOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierC(e)}
              options={qualifierCOptions}
              value={qualifierC?.map((q) => ({ value: q, label: q }))}
            />
          </div>
        </div>
      )}

      <div>
        <div className="promo-form-section">
          <div className="title-promos-form">
            <b>PASO 4: Crear encuesta</b>
          </div>
          <hr className="hr-promos-form" />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Object.keys(surveyInstances).map((instanceId, index) => (
                  <Draggable key={instanceId} draggableId={instanceId} index={index}>
                    {(provided) => (
                      <div className="survey-form-sections">
                        {surveyInstances[instanceId].type === 'text' && (
                          <SurveyTextTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                        {surveyInstances[instanceId].type === 'number' && (
                          <SurveyNumberTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                        {surveyInstances[instanceId].type === 'image' && (
                          <SurveyImageTask
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}

                        {surveyInstances[instanceId].type === 'multiplechk' && (
                          <SurveyComponentMultiple
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}

                        {surveyInstances[instanceId].type === 'multiplerb' && (
                          <SurveyComponentUnique
                            id={instanceId}
                            data={surveyInstances[instanceId]}
                            onDuplicate={(duplicatedData) =>
                              duplicateSurvey(
                                instanceId,
                                duplicatedData,
                                surveyInstances[instanceId]
                              )
                            }
                            onDelete={() => deleteSurvey(instanceId)}
                            onUpdateQuestion={handleUpdateQuestion}
                            questionNumber={index + 1}
                            showOptionsButton={true}
                            showDescriptionsCreate={true}
                            provided={provided}
                            index={index}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="survey-form-sections">
          <p>Nueva pregunta</p>
          <div className="form-control-widths">
            <select
              className={`select-promo-type form-control-widths ${
                !isClientOrSomeQualifierSelected() && 'inactive'
              }`}
              name="taskType"
              onChange={handleOptionValid}
              disabled={!isClientOrSomeQualifierSelected()}
              value={selectedOption}
              required={isRequired}
            >
              {surveySelectionType.map((option) => (
                <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                  {option.text}
                </option>
              ))}
            </select>
            <button
              className={`btnsurvey ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
              disabled={!isClientOrSomeQualifierSelected() || !isSelectionValid}
              onClick={handleClickSelectSurvey}
              type="button"
              noValidate
            >
              <span className="icon-survey-add">+</span>{' '}
              <span className="select-add-question">Agregar</span>
            </button>
          </div>
          {showImageWarning && (
            <p className="warning-text">
              Solo se permite agregar una pregunta de tipo imagen por encuesta.
            </p>
          )}
        </div>
      </div>

      <div className="promo-form-section-dates">
        <div className="title-promos-form">
          <b>PASO 5: Escoja una fecha de inicio y/o fecha final para esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <div className="d-flex align-flex-center">
          <div className="float-left">
            <label>Fecha inicio</label>
            <br />
            <input
              min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
              onChange={handleStartTimeChange}
              className="start-date"
              type="date"
              name="startTime"
              required
            />
          </div>
          {!undefinedEndTime && (
            <>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label>Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date"
                  type="date"
                  name="endTime"
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-16">
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-undefined"
              checked={undefinedEndTime}
              value={true}
            />
            <label className="ml-10" htmlFor="taskRange-undefined">
              Tarea indefinida
            </label>
          </div>
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-defined"
              checked={!undefinedEndTime}
              value={false}
            />
            <label className="ml-10" htmlFor="taskRange-defined">
              Con fecha de expiración
            </label>
          </div>
        </div>
        <div className="mb-40" />
      </div>

      <div className="promo-form-section">
        <div className="title-promos-form">
          <p>
            <b>PASO 5.1: Establezca una recurrencia para la tarea</b>
          </p>
        </div>
        <div className="form-control-width">
          <select
            className="form-control"
            name="recurrenceSelectionType"
            required
            onChange={handleRecurrenceTypeChange}
          >
            {recurrenceSelectionType?.map((obj) => (
              <option key={obj.value} name={obj.value} value={obj.value} hidden={obj.hidden}>
                {obj.text}
              </option>
            ))}
          </select>
        </div>
        {recurrenceSelection === 'weekly' && (
          <>
            <div className="form-control-width mt-20">
              <p>
                Se repite cada{' '}
                <div className="number-input-changer-container ">
                  <input
                    className="number-input-changer"
                    type="text"
                    value={recurrenceInterval}
                    onChange={onChangeRecurrenceInterval}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    onClick={increaseRecurrenceInterval}
                    className="number-input-changer-btn plus"
                  >
                    +
                  </div>
                  <div
                    onClick={decreaseRecurrenceInterval}
                    className="number-input-changer-btn minus"
                  >
                    -
                  </div>
                </div>{' '}
                semana
                {recurrenceInterval && recurrenceInterval > 1 ? 's' : ''}{' '}
              </p>
            </div>
            <div className="form-control-width mt-20">
              <p>Escoja los día(s)</p>
              <div className="days-btn-group">
                {recurrenceWeekDays.map((day, index) => (
                  <button
                    type="button"
                    onClick={() => handleToggleDay(day)}
                    value={day.value}
                    title={day.value}
                    key={index}
                    className={`days-btn ${
                      recurrenceWeekDaysSelection.find((d) => d.value === day.value) && 'active'
                    }`}
                  >
                    {day.text}
                  </button>
                ))}
              </div>
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={
                    recurrenceWeekDaysSelection && recurrenceWeekDaysSelection.length > 0
                      ? JSON.stringify(recurrenceWeekDaysSelection)
                      : null
                  }
                />
              </div>
            </div>
          </>
        )}
        <p className="small text-muted mt-20">{buildRecurrenceMessage()}</p>
        <div className="mb-50" />
      </div>
    </>
  )
}
export default SurveyTask

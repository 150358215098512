import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import axios from 'axios'
import cookie from 'react-cookies'
import { RRule, SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import { replaceAccentedCharacters } from '../../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

let countsCache = {
  brand: {},
  category: {}
}

const InventoryCaptureTask = ({
  setCloningTask,
  cloningTask,
  taskData,
  readyToCollect,
  setSaving,
  formDataCollector,
  isStep3Completed,
  setIsStep3Completed
}) => {
  const [description, setDescription] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [brand, setBrand] = useState('')
  const [category, setCategory] = useState('')
  const [productSelection, setProductSelection] = useState('')
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [undefinedEndTime, setUndefinedEndTime] = useState(true)
  const [recurrenceSelection, setRecurrenceSelection] = useState('')
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [recurrenceWeekDaysSelection, setRecurrenceWeekDaysSelection] = useState([])
  const [productsBase, setProductsBase] = useState({})
  const [productCount, setProductCount] = useState('...')
  const [unitOfSale, setUnitOfSale] = useState('')
  const [unitOfSale1Count, setUnitOfSale1Count] = useState(0)
  const [unitOfSale2Count, setUnitOfSale2Count] = useState(0)
  const [isUnitOfSale1Available, setIsUnitOfSale1Available] = useState(false)
  const [isUnitOfSale2Available, setIsUnitOfSale2Available] = useState(false)

  const productSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'brand', text: 'Marca' },
    { value: 'category', text: 'Categoría' },
    { value: 'individual_products', text: 'Producto individual' }
  ]
  const recurrenceSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'daily', text: 'Todos los días' },
    { value: 'weekly', text: 'Semanal' }
  ]

  const recurrenceWeekDays = [
    { rrlue: RRule.MO, value: 'Lunes', text: 'L' },
    { rrlue: RRule.TU, value: 'Martes', text: 'M' },
    { rrlue: RRule.WE, value: 'Miércoles', text: 'M' },
    { rrlue: RRule.TH, value: 'Jueves', text: 'J' },
    { rrlue: RRule.FR, value: 'Viernes', text: 'V' },
    { rrlue: RRule.SA, value: 'Sábado', text: 'S' },
    { rrlue: RRule.SU, value: 'Domingo', text: 'D' }
  ]

  const isUnitOfSale1Valid =
    (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) ||
    unitOfSale === 'unit_of_sale_1' ||
    isUnitOfSale1Available
  const isUnitOfSale2Valid =
    (Number(productCount) === unitOfSale2Count && unitOfSale2Count > 0) ||
    unitOfSale === 'unit_of_sale_2' ||
    isUnitOfSale2Available
  const isSelectDisabled =
    (!isUnitOfSale1Valid && !isUnitOfSale2Valid) || Number(productCount) === 0

  const openUrlNewWindow = (url) => {
    window.open(url, '_blank')
  }

   ////// Stops fetchs when page is changed
   const abortController = new AbortController()
   var abortClientController = new AbortController()
   const abortCurrentFetchs = () => {
     abortClientController.abort();
     abortController.abort();
   };
   window.addEventListener("beforeunload", abortCurrentFetchs);
   //////

   // Stops client fetch when is unfocus
   const abortClientFetchs = () => {
    abortClientController.abort();
   };
   //

  const onChangeRadios = (event) => {
    if (event.target.value === 'true') {
      setUndefinedEndTime(true)
      setEndTime(null)
    } else {
      setUndefinedEndTime(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      increaseRecurrenceInterval()
    } else if (e.keyCode === 40) {
      decreaseRecurrenceInterval()
    }
  }

  const handleToggleDay = (day) => {
    const dayVal = day?.value
    const recurrenceWeekDaySelection = recurrenceWeekDays.find((day) => day.value === dayVal)
    if (!recurrenceWeekDaySelection) {
      return
    }

    const index = recurrenceWeekDaysSelection.findIndex(
      (day) => day.value === recurrenceWeekDaySelection.value
    )
    if (index === -1) {
      setRecurrenceWeekDaysSelection([...recurrenceWeekDaysSelection, recurrenceWeekDaySelection])
    } else {
      let arr = [...recurrenceWeekDaysSelection]
      arr.splice(index, 1)
      setRecurrenceWeekDaysSelection(arr)
    }
  }

  const onChangeRecurrenceInterval = (event) => {
    let strNum = event?.target?.value
    if (!strNum) {
      setRecurrenceInterval(1)
      return
    }
    if (strNum) {
      let num = Number(strNum)
      if (isNaN(num)) return
      if (num < 1) num = 1
      if (num > 99) num = 99
      setRecurrenceInterval(num)
    }
  }

  const handleRecurrenceTypeChange = (event) => {
    if (event?.target?.value) {
      setRecurrenceSelection(event?.target?.value)
    }
  }

  const handleStartTimeChange = (event) => {
    if (event?.target?.value) {
      setStartTime(event?.target?.value)
    }
  }

  const handleEndTimeChange = (event) => {
    if (event?.target?.value) {
      setEndTime(event?.target?.value)
    }
  }

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event.map((e) => e.value))
    }
  }

  const checkUnitsOfSaleAvailability = () => {
    const hasUnitOfSale1 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[0] && product.packages[0].unit && product.packages[0].unit !== '-'
      )
    const hasUnitOfSale2 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[1] && product.packages[1].unit && product.packages[1].unit !== '-'
      )
    setIsUnitOfSale1Available(hasUnitOfSale1)
    setIsUnitOfSale2Available(hasUnitOfSale2)
  }

  useEffect(() => {
    checkUnitsOfSaleAvailability()
  }, [products])

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  const handleProductSelectionChange = (event) => {
    setProductSelection(event.target.value)
    setProducts([])
    setUnitOfSale('')
    setBrand(null)
    setCategory(null)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const isAnyKindOfProductSelected = () => {
    if (products?.length > 0 || brand || category) {
      return true
    }
    return false
  }

  const isClientOrSomeQualifierSelected = () => {
    if (clients?.length > 0 || isSomeQualifierSelected()) {
      return true
    }
    return false
  }

  const isSomeQualifierSelected = () => {
    if (qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0) {
      return true
    }
    return false
  }

  const noAvailableQualifiers = () => {
    if (
      (!qualifierAOptions?.length || qualifierAOptions?.length === 0) &&
      (!qualifierBOptions?.length || qualifierBOptions?.length === 0) &&
      (!qualifierCOptions?.length || qualifierCOptions?.length === 0)
    ) {
      return true
    }
    return false
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
  }

  const handleUnitOfSaleChange = (event) => {
    setUnitOfSale(event.target.value)
  }

  const handleBrandsChange = (event) => {
    if (event?.brand) {
      setBrand(event.brand)
      setUnitOfSale('')
      fetchDataFromServerPackage(event.brand.db_ref, event.brand.name)
    }
  }

  const handleCategoryChange = (event) => {
    if (event?.category) {
      setCategory(event.category)
      setUnitOfSale('')
      fetchDataFromServerPackage('', '', event.category.db_ref, event.category.description)
    }
  }

  const resolveEndTime = () => {
    if (undefinedEndTime) {
      return (
        <>
          de forma <span className="highlight-blue">indefinida</span>
        </>
      )
    }
    if (endTime != '') {
      return (
        <>
          hasta el <span className="highlight-blue">{getFormattedDateOnly(endTime)}</span>
        </>
      )
    }
  }

  const increaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval + 1 } })
  }

  const decreaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval - 1 } })
  }

  const getFormattedDateOnly = (date) => {
    const d = new Date(fixDateString(date))
    return d.toLocaleDateString()
  }

  const truncatePreviewText = (text) => {
    if (text?.length > 15) {
      return text.substring(0, 15) + '...'
    }
    return text
  }

  const buildRecurrence = () => {
    let rule
    switch (recurrenceSelection) {
      case 'daily':
        rule = new RRule({
          freq: RRule.DAILY
        })
        break
      case 'weekly':
        rule = new RRule({
          freq: RRule.WEEKLY,
          interval: recurrenceInterval,
          byweekday: recurrenceWeekDaysSelection.map((day) => day.rrlue)
        })
        break
    }
    return rule
  }

  const buildRecurrenceMessage = () => {
    let rule = buildRecurrence()
    if (rule && startTime) {
      return (
        <>
          Esta tarea se ejecutará <span className="highlight-blue">{rule.toText(SPANISH)}</span> a
          partir del <span className="highlight-blue">{getFormattedDateOnly(startTime)}</span>{' '}
          {resolveEndTime()}
        </>
      )
    }
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3){
        abortClientController = new AbortController()
        return fetch(`${BACKEND_URL}/businesses/1/clients?q=${inputValue}&active=true`, {
          method: 'GET',
          signal: abortClientController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
        .then((response) => 
          response.json()
        ) 
        .then((data) => 
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.name} - ${cl.db_ref}`,
            client: cl
          }))
        )
        .catch((error) => {
          if(error.name != 'AbortError'){
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const getQualifiers = () => {
    fetch(BACKEND_URL + '/promo_qualifiers', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result)
      })
      .catch((e) => console.log(e))
  }

  const displayProductCount = (product_count) => {
    if (product_count == '...') {
      return 'Cargando...'
    }
    let brandCountNum = Number(product_count)
    return isNaN(brandCountNum)
      ? '...'
      : `${brandCountNum} - ${
          brandCountNum > 1 || brandCountNum == 0 ? 'PRODUCTOS ACTIVOS' : 'PRODUCTO ACTIVO'
        }`
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    if (_qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0) {
      return 'success'
    }
    return null
  }

  const getProductsCount = async () => {
    let type = ''
    let typeData = null
    if (category) {
      type = 'category'
      typeData = category.db_ref
    }
    if (brand) {
      type = 'brand'
      typeData = brand.db_ref
    }
    if (typeData) {
      if (countsCache[type][typeData]) {
        setProductCount(countsCache[type][typeData])
      } else {
        setProductCount('...')
        let resp = await fetch(
          BACKEND_URL + `/businesses/1/products?by_${type}=${typeData}&count_only=true`,
          {
            method: 'GET',
            signal: abortController.signal,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
        if (resp.status != 200) {
          console.error('Error al obtener el conteo de productos')
          setProductCount(0)
        }

        let data = await resp.json()
        countsCache[type][typeData] = data.result
        setProductCount(data.result)
      }
    } else {
      setProductCount('...')
    }
  }

  const clearProductBrandOrCategory = () => {
    setBrand(null)
    setCategory(null)
  }

  const fetchDataFromServerPackage = (
    byBrand = '',
    brandName = '',
    byCategory = '',
    categoryName = ''
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${BACKEND_URL}/businesses/1/products?page=1` +
          `${byBrand ? '&by_brand=' + byBrand : ''}` +
          `${byCategory ? '&by_category=' + byCategory : ''}` +
          '&package_only=true',
        {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setUnitOfSale1Count(data.total_unidad_de_venta1)
          setUnitOfSale2Count(data.total_unidad_de_venta2)
          resolve(data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          reject(error)
        })
    })
  }

  const getProductsBaseData = () => {
    fetch(BACKEND_URL + '/businesses/1/products/promo_necessary_data', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions = qualifiers?.qualifier_2.map((value) => ({
    value: value,
    label: value,
    qualifier_2: value
  }))

  const qualifierBOptions = qualifiers?.qualifier_3.map((value) => ({
    value: value,
    label: value,
    qualifier_3: value
  }))

  const qualifierCOptions = qualifiers?.qualifier_4.map((value) => ({
    value: value,
    label: value,
    qualifier_4: value
  }))

  const getProducts = async (inputValue) => {
    await sleep(1000)

    return fetch(
      `${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(
        inputValue
      )}&productactive=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        return data.table.map((p) => ({
          value: p.name,
          label: `${p.name} - ${p.db_ref}`,
          product: p
        }))
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleClientOption = (options) => {
    setClients(options)
  }

  const handleProductOption = (options) => {
    if (!options?.product) {
      return
    }
    let alreadyExist = products.find((e) => e.id === options.product.id)
    if (alreadyExist) {
      return
    }
    setProducts([...products, options.product])
  }

  const sleep = (ms) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const getTaskQuantity = () => {
    let taskQuantity = 0
    if (products?.length > 0) {
      taskQuantity = products.length
    }
    if (productCount != '...' && !isNaN(productCount)) {
      taskQuantity = productCount
    }
    return taskQuantity
  }

  const collectData = () => {
    let rule = buildRecurrence()
    let jsonData = {
      description: description,
      recurrence_format: rule.toString(),
      recurrence_interval: recurrenceInterval,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(startTime),
      end_date: fixDateString(endTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      products_selection_type: productSelection,
      category_db_ref: category?.db_ref,
      category_name: category?.description,
      brand_db_ref: brand?.db_ref,
      brand_name: brand?.name,
      products_db_ref: products.map((p) => p.db_ref).sort(),
      product_names: products.map((p) => p.db_ref + ' ' + p.name).sort(),
      unit_of_sale: unitOfSale,
      task_quantity: getTaskQuantity()
    }
    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      setCloningTask(true)
      setDescription(taskData.description)

      if (taskData.client_names?.length > 0) {
        setClientOrAttribute('client')
        let _clients = []
        for (const clientName of taskData.qualifier_1) {
          let c = await getClient(clientName)
          if (c?.length > 0) {
            _clients.push(c[0])
          }
        }
        setClients(_clients)
      } else {
        setClientOrAttribute('qualifier')
        if (qualifierAOptions?.length > 0) {
          setQualifierA(taskData.qualifier_2)
        }
        if (qualifierBOptions?.length > 0) {
          setQualifierB(taskData.qualifier_3)
        }
        if (qualifierCOptions?.length > 0) {
          setQualifierC(taskData.qualifier_4)
        }
      }

      switch (taskData.products_selection_type) {
        case 'individual_products':
          {
            setProductSelection('individual_products')
            let _products = []
            for (const productDbRef of taskData.products_db_ref) {
              let p = await getProducts(productDbRef)
              if (p?.length > 0) {
                _products.push(p[0]?.product)
              }
            }
            let cleanProducts = _products.filter(
              (value, index, self) => index === self.findIndex((t) => t.db_ref === value.db_ref)
            )
            setProducts(cleanProducts)

            if (
              (isUnitOfSale1Available && taskData.unit_of_sale === 'unit_of_sale_1') ||
              (isUnitOfSale2Available && taskData.unit_of_sale === 'unit_of_sale_2')
            ) {
              setUnitOfSale(taskData.unit_of_sale)
            }
          }
          break
        case 'brand':
          {
            setProductSelection('brand')
            let _brand = productsBase?.brand?.find((b) => b.db_ref === taskData.brand_db_ref)
            setBrand(_brand)
            fetchDataFromServerPackage(taskData.brand_db_ref, taskData.brand_name).then(() => {
              if (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) {
                setUnitOfSale(taskData.unit_of_sale)
              }
            })
          }
          break
        case 'category':
          {
            setProductSelection('category')
            let _category = productsBase?.categories?.find(
              (c) => c.db_ref === taskData.category_db_ref
            )
            setCategory(_category)
            fetchDataFromServerPackage(
              '',
              '',
              taskData.category_db_ref,
              taskData.category_name
            ).then(() => {
              if (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) {
                setUnitOfSale(taskData.unit_of_sale)
              }
            })
          }
          break
      }

      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  useEffect(() => {
    getQualifiers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData, productsBase.brand, productsBase.categories])

  useEffect(() => {
    getProductsCount()
  }, [category, brand])

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>
            PASO 2: Escriba una descripción de la tarea que será visible para sus colaboradores en
            la aplicación
          </b>
        </div>
        <hr className="hr-promos-form" />
        <div className="form-group form-control-width">
          <input
            type="text"
            maxLength="40"
            className="form-control"
            id="description"
            name="description"
            onChange={handleDescriptionChange}
            value={description}
            placeholder="Ejemplo: Tarea marca Ubiqua"
            required
          />
          <p className="small text-muted m-0 pull-right">
            Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
          </p>
        </div>
      </div>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>PASO 3: Escoja a qué clientes se debe aplicar esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por cliente individual o Atributo(s)</p>

        <div>
          <div className="form-control-width">
            <select
              onChange={handleClientOrAttributeChange}
              className={`select-appliesType form-control-width ${
                description === '' && 'inactive'
              }`}
              name="applies_target_to_type"
              value={clientOrAttribute}
              disabled={description === ''}
              required
            >
              <option value="" hidden>
                Escoja una opción
              </option>
              <option value="client">Cliente individual</option>
              {!noAvailableQualifiers() && <option value="qualifier">Atributo(s)</option>}
            </select>
          </div>
        </div>
      </div>

      {clientOrAttribute === 'client' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 3.1: Escoja los clientes que aplican para esta tarea</b>
            </p>
          </div>
          <div className="fake-validator-container">
            <input
              className="input-fake-validator"
              type="text"
              required
              value={clients.length >= 1 ? clients : null}
            />
            <AsyncSelect
              className="clients"
              placeholder={'Buscar cliente por nombre o código'}
              noOptionsMessage={() => 'No hubo resultados'}
              loadingMessage={() => 'Cargando clientes...'}
              name="selectOptionClients"
              loadOptions={getClient}
              isClearable={true}
              searchParamName={'q'}
              isSearchable={true}
              isMulti
              value={clients}
              onChange={(e) => handleClientOption(e)}
              onBlur={abortClientFetchs}
            />
          </div>
        </div>
      )}

      {clientOrAttribute === 'qualifier' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <b>
              PASO 3.1: Escoja los atributos que deben de tener los clientes para activar esta
              tarea. Mínimo debe de escoger 1 atributo
            </b>
          </div>
          <div className="qualifier-title">Atributo A</div>
          <div className="fake-validator-container" style={{ zIndex: 3 }}>
            {qualifierAOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierAOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierA(e)}
              options={qualifierAOptions}
              value={qualifierA?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo B</div>
          <div className="fake-validator-container" style={{ zIndex: 2 }}>
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierBOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierB(e)}
              options={qualifierBOptions}
              value={qualifierB?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo C</div>
          <div className="fake-validator-container">
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierCOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierC(e)}
              options={qualifierCOptions}
              value={qualifierC?.map((q) => ({ value: q, label: q }))}
            />
          </div>
        </div>
      )}

      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>PASO 4: Escoja los productos que aplican a esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por marca, categoría o productos individuales</p>
        <div className="form-control-width">
          <select
            className={`select-promo-type form-control-width ${
              !isClientOrSomeQualifierSelected() && `inactive`
            }`}
            name="taskType"
            value={productSelection}
            onChange={handleProductSelectionChange}
            disabled={!isClientOrSomeQualifierSelected()}
            required
          >
            {productSelectionType.map((option) => (
              <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-50" />
      </div>

      {productSelection === 'individual_products' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Escoja los productos que aplican a esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={products.length >= 1 ? products : null}
              />
              <AsyncSelect
                className="clients"
                placeholder={'Escribir nombre o codigo del producto'}
                noOptionsMessage={() => 'No hubo resultados'}
                loadingMessage={() => 'Cargando productos...'}
                name="selectOptionProducts"
                loadOptions={getProducts}
                isClearable={true}
                searchParamName={'q'}
                isSearchable={true}
                value={[]}
                onChange={(e, am) => {
                  handleProductOption(e)
                  am.action = 'clear'
                }}
              />
            </div>
          </div>
          {products?.length > 0 && (
            <div className="promo-form-section row">
              <table className="table table-hover promos-product-table">
                <thead>
                  <tr>
                    <th className="pl-20">CODIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>UNIDAD DE VENTA 1</th>
                    <th>UNIDAD DE VENTA 2</th>
                    <th>ACCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <tr key={`${product.id}-${index}`}>
                      <td className="pl-20">{product.db_ref}</td>
                      <td>{product.name}</td>
                      <td>
                        {product.packages[0]?.unit && (
                          <>
                            {product.packages[0]?.unit}{' '}
                            {product.packages[0]?.quantity > 1
                              ? `de ${product.packages[0]?.quantity}`
                              : ''}
                          </>
                        )}
                        {!product.packages[0]?.unit && '-'}
                      </td>
                      <td>
                        {product.packages[1]?.unit && (
                          <>
                            {product.packages[1]?.unit}{' '}
                            {product.packages[1]?.quantity > 1
                              ? `de ${product.packages[1]?.quantity}`
                              : ''}
                          </>
                        )}
                        {!product.packages[1]?.unit && '-'}
                      </td>
                      <td
                        className="delete-selected-product"
                        onClick={(e) => setProducts(products.filter((p) => p.id !== product.id))}
                      >
                        &times;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {productSelection === 'brand' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Seleccione la marca de los productos para esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={brand?.db_ref ? brand?.db_ref : null}
              />
              <Select
                isDisabled={brand ? true : false}
                placeholder={'Escoja una opcion'}
                noOptionsMessage={() => 'No hay resultados'}
                loadingMessage={() => 'Cargando...'}
                className="qualifier"
                isClearable={false}
                value={brand ? { value: brand.db_ref, label: brand.name } : null}
                onChange={(e) => handleBrandsChange(e)}
                options={productsBase?.brand?.map((option) => {
                  return { value: option.db_ref, label: option.name, brand: option }
                })}
              />
            </div>
          </div>
          {isAnyKindOfProductSelected() && (
            <div className="box-products-preview-container-pos mt-20">
              <div className="box-products-preview-pos mr-10">
                <p className="m-0" title={brand?.name}>
                  {displayProductCount(productCount)}
                </p>
                <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                  &times;
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  openUrlNewWindow(`/productos?by_brand=${brand.db_ref}&brand_name=${brand.name}`)
                }
                className="big-btn-tasks"
              >
                Ver lista de productos
              </button>
            </div>
          )}
          <div className="mb-50" />
        </div>
      )}
      {productSelection === 'category' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Seleccione la categoría de los productos para esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={category?.db_ref ? category?.db_ref : null}
              />
              <Select
                isDisabled={category ? true : false}
                placeholder={'Escoja una opcion'}
                noOptionsMessage={() => 'No hay resultados'}
                loadingMessage={() => 'Cargando...'}
                className="qualifier"
                isClearable={true}
                value={category ? { value: category.db_ref, label: category.description } : null}
                onChange={(e) => handleCategoryChange(e)}
                options={productsBase?.categories?.map((option) => {
                  return { value: option.db_ref, label: option.description, category: option }
                })}
              />
            </div>
          </div>
          {isAnyKindOfProductSelected() && (
            <div className="box-products-preview-container-pos mt-20">
              <div className="box-products-preview-pos mr-10">
                <p className="m-0" title={category?.description}>
                  {displayProductCount(productCount)}
                </p>
                <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                  &times;
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  openUrlNewWindow(
                    `/productos?by_category=${category.db_ref}&category_name=${category.description}`
                  )
                }
                className="big-btn-tasks"
              >
                Ver listado de productos
              </button>
            </div>
          )}
          <div className="mb-50" />
        </div>
      )}
      {isAnyKindOfProductSelected() && (
        <>
          <p className="title-promos-form mb-40 ">
            <b>PASO 4.2: Seleccione la unidad de venta de los productos a aplicar en la tarea</b>
          </p>
          <div className="form-control-width">
            <select
              onChange={handleUnitOfSaleChange}
              className={`select-promo-type form-control-width ${
                isSelectDisabled ? 'select-disabled-promo' : ''
              }`}
              name="base_package_promo"
              required
              value={unitOfSale || ''}
              onClick={(e) => {
                if (isSelectDisabled) {
                  e.preventDefault()
                }
              }}
            >
              <option value="" hidden>
                Escoja una opción
              </option>

              {isUnitOfSale1Valid && (
                <option key={11} value="unit_of_sale_1">
                  {`Unidad de venta 1`}
                </option>
              )}

              {isUnitOfSale2Valid && (
                <option key={12} value="unit_of_sale_2">
                  {`Unidad de venta 2`}
                </option>
              )}
            </select>
            {Number(productCount) === 0 && (
              <p className="warning-text">
                {productSelection === 'brand' &&
                  'La marca seleccionada no tiene productos disponibles. Elige una marca diferente para continuar.'}
                {productSelection === 'category' &&
                  'La categoría seleccionada no tiene productos disponibles. Elige una categoría diferente para continuar.'}
              </p>
            )}
            {isSelectDisabled && Number(productCount) !== 0 && (
              <p className="warning-text">
                Los productos seleccionados carecen de paquetes necesarios. Ver lista de productos.
              </p>
            )}
          </div>
        </>
      )}
      <div className="promo-form-section-dates">
        <div className="title-promos-form">
          <b>PASO 5: Escoja una fecha de inicio y/o fecha final para esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <div className="d-flex align-flex-center">
          <div className="float-left">
            <label>Fecha inicio</label>
            <br />
            <input
              min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
              onChange={handleStartTimeChange}
              className="start-date"
              type="date"
              name="startTime"
              required
            />
          </div>
          {!undefinedEndTime && (
            <>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label>Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date"
                  type="date"
                  name="endTime"
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-16">
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-undefined"
              checked={undefinedEndTime}
              value={true}
            />
            <label className="ml-10" htmlFor="taskRange-undefined">
              Tarea indefinida
            </label>
          </div>
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-defined"
              checked={!undefinedEndTime}
              value={false}
            />
            <label className="ml-10" htmlFor="taskRange-defined">
              Con fecha de expiración
            </label>
          </div>
        </div>
        <div className="mb-40" />
      </div>

      <div className="promo-form-section">
        <div className="title-promos-form">
          <p>
            <b>PASO 5.1: Establezca una recurrencia para la tarea</b>
          </p>
        </div>
        <div className="form-control-width">
          <select
            className="form-control"
            name="recurrenceSelectionType"
            required
            onChange={handleRecurrenceTypeChange}
          >
            {recurrenceSelectionType?.map((obj) => (
              <option key={obj.value} name={obj.value} value={obj.value} hidden={obj.hidden}>
                {obj.text}
              </option>
            ))}
          </select>
        </div>
        {recurrenceSelection === 'weekly' && (
          <>
            <div className="form-control-width mt-20">
              <p>
                Se repite cada{' '}
                <div className="number-input-changer-container ">
                  <input
                    className="number-input-changer"
                    type="text"
                    value={recurrenceInterval}
                    onChange={onChangeRecurrenceInterval}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    onClick={increaseRecurrenceInterval}
                    className="number-input-changer-btn plus"
                  >
                    +
                  </div>
                  <div
                    onClick={decreaseRecurrenceInterval}
                    className="number-input-changer-btn minus"
                  >
                    -
                  </div>
                </div>{' '}
                semana
                {recurrenceInterval && recurrenceInterval > 1 ? 's' : ''}{' '}
              </p>
            </div>
            <div className="form-control-width mt-20">
              <p>Escoja los día(s)</p>
              <div className="days-btn-group">
                {recurrenceWeekDays.map((day, index) => (
                  <button
                    type="button"
                    onClick={() => handleToggleDay(day)}
                    value={day.value}
                    title={day.value}
                    key={index}
                    className={`days-btn ${
                      recurrenceWeekDaysSelection.find((d) => d.value === day.value) && 'active'
                    }`}
                  >
                    {day.text}
                  </button>
                ))}
              </div>
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={
                    recurrenceWeekDaysSelection && recurrenceWeekDaysSelection.length > 0
                      ? JSON.stringify(recurrenceWeekDaysSelection)
                      : null
                  }
                />
              </div>
            </div>
          </>
        )}
        <p className="small text-muted mt-20">{buildRecurrenceMessage()}</p>
        <div className="mb-50" />
      </div>
    </>
  )
}
export default InventoryCaptureTask

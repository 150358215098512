import React, { useContext, useEffect, useState } from 'react'
import cookie from 'react-cookies'
import history from '../../helpers/history'
import { BonusPromoContext } from '../context/BonusPromoContext'
import { BasePromoContext } from '../context/BasePromoContext'
import { ScalePromoContext } from '../context/ScalePromoContext'
import Select from 'react-select'
import { PROMO_CLIENT_TYPE, PROMO_QUALIFIER_TYPE } from '../../constants/constant'
import AsyncSelect from 'react-select/async'
import { AssortedPromoContext } from '../context/AssortedPromoContext'
import { AssortedBonusPromoContext } from '../context/AssortedBonusPromoContext'
import { AssortedScalePromoContext } from '../context/AssortedScalePromoContext'
import axios from 'axios'

import ProductSection from '../components/sections/ProductSection'
import BonusPromoRuleSection from '../components/sections/BonusPromoRuleSection'
import DiscountPromoRuleSection from '../components/sections/DiscountPromoRuleSection'
import DateAndDescriptionSection from '../components/sections/DateAndDescriptionSection'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreatePromo = (props) => {
  const [appliesType, setAppliesType] = useState('')
  const [appliesTo, setAppliesTo] = useState([])
  const [failedCreation, setFailedCreation] = useState(false)
  const [failedCreationMessage, setFailedCreationMessage] = useState('')
  const [clientOptions, setClientOptions] = useState([])
  const [client, setClient] = useState(null)
  const [clientValue, setClientValue] = useState(null)
  const [promoType, setPromoType] = useState(null)
  const [baseBrand, setBaseBrand] = useState('')
  const [bonusBrand, setBonusBrand] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [promoRule, setPromoRule] = useState(null)
  const [description, setDescription] = useState('')
  const [checkedMaxBonus, setCheckedMaxBonus] = useState(false)
  const [scaleArray, setScaleArray] = useState([{ min: 1, max: 0, discount: 0 }])
  const [promoScaleArray, setPromoScaleArray] = useState([
    { min: 1, max: 0, inc_factor: 0, bonus_qty: 0 }
  ])
  const [isAssorted, setIsAssorted] = useState(true)
  const [promoData, setPromoData] = useState(null)
  const [cloningPromo, setCloningPromo] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })

  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])

  const promoTypeOptions = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'bonus_product', text: 'Bonificación de una referencia' },
    { value: 'assorted_bonus_product', text: 'Bonificación surtida' },
    {
      value: 'assorted_scale_discount_product',
      text: 'Descuento de una referencia / Descuento surtido'
    }
  ]

  useEffect(() => {
    fetchQualifiers()
    setQueryParams()
  }, [])

  const BONUS_PRODUCT = 'bonus_product'
  const ASSORTED_PRODUCT = 'assorted_bonus_product'
  const DISCOUNT = 'assorted_scale_discount_product'

  const { baseProducts, clearBaseProduct } = useContext(BasePromoContext)
  const { bonusProducts, clearBonusProduct } = useContext(BonusPromoContext)
  const { scaleDiscountProducts, clearScaleDiscountProduct } = useContext(ScalePromoContext)
  const { assortedProducts, assortedBonusProductQuantity } = useContext(AssortedPromoContext)
  const { assortedBonusProducts, assortedGiftBonusProductQuantity } =
    useContext(AssortedBonusPromoContext)
  const { assortedScaleProducts, clearAssortedScaleProducts } =
    useContext(AssortedScalePromoContext)
  const [noValidPackages, setNoValidPackages] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  var abortClientController = new AbortController()
  const abortCurrentFetchs = () => {
    abortClientController.abort();
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  // Stops client fetch when is unfocus
  const abortClientFetchs = () => {
   abortClientController.abort();
  };
  //

  const startCloning = async (uuid) => {
    let promoToClone = await getPromoToClone(uuid)
    if (promoToClone) {
      setCloningPromo(true)
      setIsSubmitDisabled(true)
      if (
        promoToClone.promo_type != BONUS_PRODUCT &&
        promoToClone.promo_type != ASSORTED_PRODUCT &&
        promoToClone.promo_type != DISCOUNT
      ) {
        console.log('Error en duplicacion de promos (Tipo de promo): ', promoToClone.promo_type)
        setFailedCreation(true)
        setFailedCreationMessage(
          'Hubo un error al intentar obtener: el tipo de promoción, requerido para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        setIsSubmitDisabled(false)
        return setCloningPromo(false)
      } else {
        setPromoType(promoToClone.promo_type)
        if (promoToClone.promo_type == BONUS_PRODUCT) {
          setIsAssorted(false)
        } else {
          setIsAssorted(true)
        }
      }
      setDescription(promoToClone.description)
      try {
        switch (promoToClone.applies_to_type) {
          case 'qualifier':
            {
              setAppliesType('qualifier')
              if (promoToClone.qualifier_2 !== null) {
                setQualifierA(promoToClone.qualifier_2)
                promoToClone.qualifier_2.forEach((q) => {
                  setAppliesTo([...appliesTo, q])
                })
              }
              if (promoToClone.qualifier_3 !== null) {
                setQualifierB(promoToClone.qualifier_3)
                promoToClone.qualifier_3.forEach((q) => {
                  setAppliesTo([...appliesTo, q])
                })
              }
              if (promoToClone.qualifier_4 !== null) {
                setQualifierC(promoToClone.qualifier_4)
                promoToClone.qualifier_4.forEach((q) => {
                  setAppliesTo([...appliesTo, q])
                })
              }
            }
            break
          case 'client':
            {
              setAppliesType('client')
              let clients = await getClient(promoToClone.qualifier_1)
              setClient(clients[0].client)
              setClientValue(clients)
              setAppliesTo([clients[0].client.id])
            }
            break
        }
      } catch (e) {
        console.log('Error en duplicacion de promos (Clientes o Atributos): ', e)
        setFailedCreation(true)
        setFailedCreationMessage(
          'Hubo un error al intentar obtener: Los clientes o atributos, requeridos para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        setIsSubmitDisabled(false)
        return setCloningPromo(false)
      }
      setPromoData(promoToClone)
    }
  }

  const getPromoToClone = async (uuid) => {
    const response = await fetch(BACKEND_URL + '/promos/' + uuid, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
    if (response.status === 401) {
      window.location = '/'
    }

    if (response.status != 200) {
      return false
    } else {
      let json = await response.json()
      return json
    }
  }

  const fetchQualifiers = () => {
    fetch(`${BACKEND_URL}/promo_qualifiers`, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result)
      })
      .catch((e) => console.log(e))
  }

  const setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const uuidToClone = urlParams.get('clone') || ''
    if (uuidToClone) {
      startCloning(uuidToClone)
    }
  }

  const handleChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    // when these values are reset product selected
    // should be reset in order to hide and empty table
    if (event.target.name === 'applies_target_to_type' || event.target.name === 'promoType') {
      if (event.target.name === 'applies_target_to_type') {
        clearBaseProduct()
        clearBonusProduct()
        clearScaleDiscountProduct()
        clearAssortedScaleProducts()
        setAppliesTo([])
        setQualifierA([])
        setQualifierB([])
        setQualifierC([])
        setClientValue(null)
        setClient(null)
        setAppliesType(obj.applies_target_to_type)
        setPromoScaleArray([{ min: 1, max: 0, inc_factor: 0, bonus_qty: 0 }])
        setScaleArray([{ min: 1, max: 0, discount: 0 }])
      } else {
        clearBaseProduct()
        clearBonusProduct()
        clearScaleDiscountProduct()
        clearAssortedScaleProducts()
        setAppliesType('')
        setAppliesTo([])
        setQualifierA([])
        setQualifierB([])
        setQualifierC([])
        setClientValue(null)
        setClient(null)
        setPromoType(obj.promoType)
        handleAssortedPromo(obj.promoType)
        setPromoScaleArray([{ min: 1, max: 0, inc_factor: 0, bonus_qty: 0 }])
        setScaleArray([{ min: 1, max: 0, discount: 0 }])
      }
    } else {
      setStartTime(obj.startTime)
      setEndTime(obj.endTime)
    }
  }

  const handleAssortedPromo = (promoType) => {
    if (promoType === BONUS_PRODUCT) {
      setIsAssorted(false)
    } else {
      setIsAssorted(true)
    }
  }

  const uniqueProductsById = (products) => {
    return [...new Map(products.map((item) => [item['id'], item])).values()]
  }

  const resetProductSelected = (event) => {
    clearBaseProduct()
    clearBonusProduct()
    clearScaleDiscountProduct()
    clearAssortedScaleProducts()
    setPromoScaleArray([{ min: 1, max: 0, inc_factor: 0, bonus_qty: 0 }])
    setScaleArray([{ min: 1, max: 0, discount: 0 }])
  }

  const cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      window.location = '/promociones'
    }
  }

  const handleScaleDiscountArrayErrors = () => {
    for (let index = 0; index < scaleArray.length; index++) {
      const currentScale = scaleArray[index]
      if (currentScale.min === 0) {
        setFailedCreationMessage(
          'Minimo de venta en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.max === 0) {
        setFailedCreationMessage(
          'Maximo de venta en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.discount === 0) {
        setFailedCreationMessage(
          'Campo de porcentaje de descuento en escala ' + (index + 1) + ' debe ser entre 1 y 100.'
        )
        return true
      } else if (currentScale.min >= currentScale.max) {
        setFailedCreationMessage('La escala ' + (index + 1) + ' debe ser consecutiva.')
        return true
      }
    }
    return false
  }

  const handleScaleBonusArrayErrors = () => {
    for (let index = 0; index < promoScaleArray.length; index++) {
      const currentScale = promoScaleArray[index]
      if (currentScale.min === 0) {
        setFailedCreationMessage(
          'Minimo de venta en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.max === 0) {
        setFailedCreationMessage(
          'Maximo de venta en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.inc_factor === 0) {
        setFailedCreationMessage(
          'Factor de incremento en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.bonus_qty === 0) {
        setFailedCreationMessage(
          'Campo de cantidad bonificada en escala ' + (index + 1) + ' tiene que ser mayor a 0.'
        )
        return true
      } else if (currentScale.min >= currentScale.max) {
        setFailedCreationMessage('La escala ' + (index + 1) + ' debe ser consecutiva.')
        return true
      }
    }
    return false
  }

  const isEmpty = (str) => !str || str.length === 0

  const base_Products_Details = (products) =>
    products.slice(0, 5).map((product) => ({
      db_ref: product.db_ref,
      name: product.base_product_name,
      id: Array.isArray(product.base_product_ids)
        ? product.base_product_ids[0]
        : product.base_product_ids
    }))

  const sendCreateParams = (event) => {
    event.preventDefault()
    let limit_bonus_quantity = 0
    let bonus_product_quantity = 0
    let baseAssortedProducts = []
    baseAssortedProducts = uniqueProductsById(assortedProducts)
    const bonus_quantity_zero = bonusProducts.filter((obj) => obj.bonus_quantity <= 0)
    const base_quantity_zero = baseProducts.filter((obj) => obj.base_quantity <= 0)
    const appliesToMessageError =
      'Error al intentar guardar la promoción: debes escoger al menos un cliente o, como mínimo escoger 1 atributo.'
    if (appliesTo.length === 0) {
      setFailedCreation(true)
      setFailedCreationMessage(appliesToMessageError)
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (
      appliesType === PROMO_QUALIFIER_TYPE &&
      qualifierA.length === 0 &&
      qualifierB.length === 0 &&
      qualifierC.length === 0
    ) {
      setFailedCreation(true)
      setFailedCreationMessage(appliesToMessageError)
      return setTimeout(() => setFailedCreation(false), 15000)
    }
    if (promoType === BONUS_PRODUCT || promoType === ASSORTED_PRODUCT) {
      limit_bonus_quantity = Number(bonusProducts[0]?.bonus_limit)
      bonus_product_quantity = Number(bonusProducts[0]?.bonus_quantity)
    }
    if (promoType === DISCOUNT && handleScaleDiscountArrayErrors()) {
      setFailedCreation(true)
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (
      (promoType === BONUS_PRODUCT || promoType === ASSORTED_PRODUCT) &&
      promoRule === 'scale' &&
      handleScaleBonusArrayErrors()
    ) {
      setFailedCreation(true)
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (
      promoType === BONUS_PRODUCT &&
      promoRule === 'one_increment' &&
      (bonus_quantity_zero.length > 0 || base_quantity_zero.length > 0)
    ) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Hay un error en el formulario, verifique la información, Existen bonificaciones con cantidades en 0.'
      )
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (
      (promoType === BONUS_PRODUCT || promoType === ASSORTED_PRODUCT) &&
      checkedMaxBonus === true &&
      limit_bonus_quantity < bonus_product_quantity
    ) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'La cantidad introducida es menor a la necesaria para activar la bonificación.'
      )
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (noValidPackages) {
      return
    } else {
      const selectedProducts =
        baseProducts.length > 0
          ? uniqueProductsById(baseProducts)
          : uniqueProductsById(assortedProducts)
      const brandDetails = baseBrand
        ? {
            db_ref: baseBrand.db_ref,
            id: baseBrand.id,
            name: baseBrand.name
          }
        : null
      const productDetails =
        selectedProducts.length > 0 ? base_Products_Details(selectedProducts) : null
      const promoDetails = []
      if (brandDetails) {
        promoDetails.push(brandDetails)
      } else if (productDetails) {
        promoDetails.push(...productDetails)
      }
      const body = {
        start_time: startTime,
        end_time: endTime,
        business_id: 1,
        promo_details: promoDetails,
        business_uid: cookie.load('business_tenant_uid'),
        applies_to_type: appliesType,
        applies_to:
          appliesType === PROMO_CLIENT_TYPE
            ? appliesTo
            : [
                qualifierA.length > 0 ? 'qualifier_2' : null,
                qualifierB.length > 0 ? 'qualifier_3' : null,
                qualifierC.length > 0 ? 'qualifier_4' : null
              ],
        qualifier_1: appliesType === PROMO_CLIENT_TYPE ? client.db_ref : null,
        qualifier_2: qualifierA.length > 0 ? qualifierA : null,
        qualifier_3: qualifierB.length > 0 ? qualifierB : null,
        qualifier_4: qualifierC.length > 0 ? qualifierC : null,
        qualifier_5: null,
        description: isEmpty(description) ? '-' : description
      }
      if (promoType === BONUS_PRODUCT) {
        body['products'] = [
          {
            basePackageQuantity: baseProducts[0].basePackageQuantity,
            base_package: baseProducts[0].base_package,
            base_product_ids: baseProducts[0].base_product_ids,
            base_quantity: baseProducts[0].base_quantity,
            bonus_package: bonusProducts[0].bonus_package,
            bonus_product_ids: bonusProducts[0].bonus_product_ids,
            bonus_quantity: bonusProducts[0].bonus_quantity,
            bonus_limit: bonusProducts[0].bonus_limit,

            base_product_uid: baseProducts[0].base_product_uid,
            base_product_db_ref: baseProducts[0].base_product_db_ref,
            base_product_name: baseProducts[0].base_product_name,
            base_package_uid: baseProducts[0].base_package_uid,

            bonus_product_uid: bonusProducts[0].bonus_product_uid,
            bonus_product_db_ref: bonusProducts[0].bonus_product_db_ref,
            bonus_product_name: bonusProducts[0].bonus_product_name,
            bonus_package_uid: bonusProducts[0].bonus_package_uid,
            db_ref: baseProducts[0].db_ref,
            name: baseProducts[0].name,
            id: baseProducts[0].id,
            tax: baseProducts[0].tax,

            promo_rule_type: promoRule,
            promo_scale_array: promoScaleArray
          }
        ]
        postCreateBonusProductPromo(body)
      }
      if (promoType === ASSORTED_PRODUCT) {
        body['products'] = [
          {
            bonus_product_uid: bonusProducts[0].bonus_product_uid,
            bonus_product_db_ref: bonusProducts[0].bonus_product_db_ref,
            bonus_product_name: bonusProducts[0].bonus_product_name,
            bonus_package_uid: bonusProducts[0].bonus_package_uid,
            bonus_package: bonusProducts[0].bonus_package,

            bonus_package_units: bonusProducts[0].bonus_package,
            bonus_product_ids: bonusProducts[0].bonus_product_ids,
            bonus_product_uids: bonusProducts[0].bonus_product_uid,
            bonus_package_uids: bonusProducts[0].bonus_package_uid,
            bonus_package_hash: JSON.stringify(bonusProducts[0].bonus_package),
            bonus_limit: bonusProducts[0].bonus_limit,
            bonus_quantity: bonusProducts[0].bonus_quantity,

            base_quantity: assortedBonusProductQuantity,
            base_package_units: baseAssortedProducts.map((pr) => pr.base_package),
            base_product_ids: baseAssortedProducts.map((pr) => pr.id),
            base_product_uids: baseAssortedProducts.map((pr) => pr.base_product_uid),
            base_package_uids: baseAssortedProducts.map((pr) => pr.base_package_uid),
            base_package_hash: JSON.stringify(baseAssortedProducts[0].base_package_hash),
            promo_rule_type: promoRule,
            base_brand: baseBrand?.db_ref,
            promo_scale_array: promoScaleArray
          }
        ]
        postCreateAssortedBonusProductPromo(body)
      }

      if (promoType === DISCOUNT) {
        body['products'] = [
          {
            base_package_units: baseAssortedProducts.map((pr) => pr.base_package),
            base_product_ids: baseAssortedProducts.map((pr) => pr.id),
            base_product_uids: baseAssortedProducts.map((pr) => pr.base_product_uid),
            base_package_uids: baseAssortedProducts.map((pr) => pr.base_package_uid),
            base_package_hash: JSON.stringify(baseAssortedProducts[0].base_package_hash),
            scale_discount_hash: scaleArray,
            base_brand: baseBrand?.db_ref
          }
        ]
        postCreateAssortedScaleDiscountPromo(body)
      }
    }
  }

  const postCreateBonusProductPromo = (body) => {
    fetch(`${BACKEND_URL}/bonus_product_promos`, {
      method: 'POST',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const postCreateAssortedBonusProductPromo = (body) => {
    fetch(`${BACKEND_URL}/assorted_bonus_product_promos`, {
      method: 'POST',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const postCreateAssortedScaleDiscountPromo = (body) => {
    fetch(`${BACKEND_URL}/assorted_scale_discount_product_promos`, {
      method: 'POST',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (response) => {
    // evaluate responses for status 422 or 500
    if (response.status === 422) {
      setFailedCreation(true)
      let obj = response.json()
      setFailedCreationMessage(Object.keys(obj.data).map((k) => obj.data[k]))
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.status === 500) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Error al crear nueva promoción: existe otra promoción creada con los mismos valores. Por favor, editar esta promoción.'
      )
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.status === 404) {
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en el formulario, verifique la información.')
      return setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.ok) {
      history.push({
        pathname: '/promociones',
        params: { successCreateForNewPromo: true }
      })
    } else {
      console.log('error: ', response)
    }
  }

  const qualifierAOptions = qualifiers?.qualifier_2.map((value) => ({
    value: value,
    label: value,
    qualifier_2: value
  }))

  const qualifierBOptions = qualifiers?.qualifier_3.map((value) => ({
    value: value,
    label: value,
    qualifier_3: value
  }))

  const qualifierCOptions = qualifiers?.qualifier_4.map((value) => ({
    value: value,
    label: value,
    qualifier_4: value
  }))

  const handleClientOption = (event) => {
    if (event != null) {
      setClientValue(event)
      setClient(event.client)
      setAppliesTo([event.client.id])
    } else {
      setClientValue(null)
      setClient(null)
      setAppliesTo([])
    }
  }
  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event.map((e) => e.value))
      setAppliesTo([...appliesTo, event.value])
    } else {
      setQualifierA([])
      handleDeleteQualifier(qualifierA)
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event.map((e) => e.value))
      setAppliesTo([...appliesTo, event.value])
    } else {
      setQualifierB([])
      handleDeleteQualifier(qualifierB)
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event.map((e) => e.value))
      setAppliesTo([...appliesTo, event.value])
    } else {
      setQualifierC([])
      handleDeleteQualifier(qualifierC)
    }
  }

  const handleDeleteQualifier = (qualifier) => {
    setAppliesTo(appliesTo.filter((item) => item !== qualifier))
  }

  const sleep = (ms) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const getClient = async (inputValue) => {
  if (inputValue.length >= 3){
      resetProductSelected()
      abortClientController = new AbortController()
      return fetch(`${BACKEND_URL}/businesses/1/clients?q=${inputValue}&active=true`, {
        method: 'GET',
        signal: abortClientController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
      .then((response) => 
        response.json()
      ) 
      .then((data) => 
        data.table.map((cl) => ({
          value: cl.name,
          label: `${cl.name} - ${cl.db_ref}`,
          client: cl
        }))
      )
      .catch((error) => {
        if(error.name != 'AbortError'){
          console.error('Error fetching clients:', error)
          return []
        }
      })
  }
}

  return (
    <div className={`main-view ${props.collapsed ? 'collapsed' : ''}`}>
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong className="ml-65">{failedCreationMessage}</strong>
        </div>
      )}
      <div>
        <div className="show-title">
          <h2 style={{ fontWeight: 600, fontSize: 28 }}>Promoción nueva</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="create-promo-wrapper">
              <form onSubmit={sendCreateParams.bind(this)}>
                <div className="inline-block full-width">
                  <div className="float-left">
                    <h4>Ingrese los siguientes datos para crear una promoción nueva</h4>
                  </div>
                  <div className="float-right">
                    <button onClick={cancelCreate} className="cancel-button">
                      Cancelar
                    </button>
                    <input
                      className="save-button"
                      value="Guardar"
                      type="submit"
                      disabled={isSubmitDisabled}
                    />
                  </div>
                </div>
                {cloningPromo && (
                  <div className="promo-form-section">
                    <div className="title-promos-form">
                      <b>Cargando promoción...</b>
                    </div>
                  </div>
                )}
                {!cloningPromo && (
                  <div className="promo-card">
                    <div className="title-promos-form">
                      <b>1. Tipo de promoción</b>
                    </div>
                    <hr className="hr-promo-card" />
                    <p className="promo-card-subtitle">1.1: Escoja el tipo de promoción</p>
                    <select
                      onChange={handleChange}
                      value={promoType}
                      className="select-promo-type"
                      name="promoType"
                      required
                    >
                      {promoTypeOptions.map((option) => (
                        <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {promoType !== null && !cloningPromo && (
                  <div className="promo-card">
                    <div className="title-promos-form">
                      <b>2. Clientes o atributo(s)</b>
                    </div>
                    <hr className="hr-promo-card" />
                    <p className="promo-card-subtitle">
                      2.1: Escoja cómo se debe de aplicar la promoción, si es por cliente individual
                      o por atributo(s)
                    </p>
                    <div>
                      <select
                        onChange={handleChange}
                        className="select-promo-type"
                        name="applies_target_to_type"
                        value={appliesType}
                        required
                      >
                        <option value="" hidden>
                          Escoger una opcion
                        </option>
                        <option value="client">Cliente individual</option>
                        <option value="qualifier">Atributo(s)</option>
                      </select>
                    </div>

                    {appliesType === 'client' && (
                      <>
                        <p className="promo-card-subtitle mt-33">
                          2.2: Escoja al cliente que debe de aplicar la promoción
                        </p>
                        <div className="promo-card-heading">Cliente</div>
                        <AsyncSelect
                          placeholder={'Buscar cliente por nombre o código'}
                          noOptionsMessage={() => 'Buscar Clientes'}
                          loadingMessage={() => 'Cargando clientes...'}
                          name="selectOptionClients"
                          loadOptions={getClient}
                          className="clients"
                          isClearable={true}
                          searchParamName={'q'}
                          isSearchable={!client}
                          value={clientValue}
                          onChange={(e) => handleClientOption(e)}
                          onBlur={abortClientFetchs}
                        />
                      </>
                    )}
                    {appliesType === 'qualifier' && (
                      <>
                        <p className="promo-card-subtitle mt-33">
                          2.2: Escoja los atributos que deben de tener los clientes para activar la
                          promoción. Mínimo debe escoger 1 atributo.
                        </p>
                        {qualifierAOptions.length === 0 && (
                          <>
                            <div className="promo-card-heading">Atributo A</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier mb-30"
                              isDisabled={true}
                              isClearable={true}
                              isMulti
                              onChange={(e) => handleChangeQualifierA(e)}
                              options={qualifierAOptions}
                            />
                          </>
                        )}
                        {qualifierAOptions.length > 0 && (
                          <>
                            <div className="promo-card-heading">Atributo A</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier mb-30"
                              isDisabled={false}
                              isClearable={true}
                              isMulti
                              value={qualifierA?.map((q) => ({ value: q, label: q }))}
                              onChange={(e) => handleChangeQualifierA(e)}
                              options={qualifierAOptions}
                            />
                          </>
                        )}
                        {qualifierBOptions.length === 0 && (
                          <>
                            <div className="promo-card-heading">Atributo B</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier mb-30"
                              isDisabled={true}
                              isClearable={true}
                              isMulti
                              onChange={(e) => handleChangeQualifierB(e)}
                              options={qualifierBOptions}
                            />
                          </>
                        )}
                        {qualifierBOptions.length > 0 && (
                          <>
                            <div className="promo-card-heading">Atributo B</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier mb-30"
                              isDisabled={false}
                              isClearable={true}
                              isMulti
                              value={qualifierB?.map((q) => ({ value: q, label: q }))}
                              onChange={(e) => handleChangeQualifierB(e)}
                              options={qualifierBOptions}
                            />
                          </>
                        )}
                        {qualifierCOptions.length === 0 && (
                          <>
                            <div className="promo-card-heading">Atributo C</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier"
                              isDisabled={true}
                              isClearable={true}
                              isMulti
                              onChange={(e) => handleChangeQualifierC(e)}
                              options={qualifierCOptions}
                            />
                          </>
                        )}
                        {qualifierCOptions.length > 0 && (
                          <>
                            <div className="promo-card-heading">Atributo C</div>
                            <Select
                              placeholder={'Buscar o escoger una opcion'}
                              noOptionsMessage={() => 'Buscar'}
                              loadingMessage={() => 'Cargando...'}
                              className="qualifier"
                              isDisabled={false}
                              isClearable={true}
                              isMulti
                              value={qualifierC?.map((q) => ({ value: q, label: q }))}
                              onChange={(e) => handleChangeQualifierC(e)}
                              options={qualifierCOptions}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}

                {promoType !== null && (
                  <>
                    <ProductSection
                      promoData={promoData}
                      cloningPromo={cloningPromo}
                      setCloningPromo={setCloningPromo}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      appliesTo={appliesTo}
                      promoType={promoType}
                      isAssorted={isAssorted}
                      baseBrand={baseBrand}
                      setBaseBrand={setBaseBrand}
                      bonusBrand={bonusBrand}
                      setBonusBrand={setBonusBrand}
                      setPromoRule={setPromoRule}
                      setPromoScaleArray={setPromoScaleArray}
                      setCheckedMaxBonus={setCheckedMaxBonus}
                      setScaleArray={setScaleArray}
                      failedCreation={failedCreation}
                      setFailedCreation={setFailedCreation}
                      setFailedCreationMessage={setFailedCreationMessage}
                      setNoValidPackages={setNoValidPackages}
                    />

                    {(promoType === BONUS_PRODUCT || promoType === ASSORTED_PRODUCT) && (
                      <BonusPromoRuleSection
                        cloningPromo={cloningPromo}
                        promoType={promoType}
                        isAssorted={isAssorted}
                        promoRule={promoRule}
                        setPromoRule={setPromoRule}
                        promoScaleArray={promoScaleArray}
                        setPromoScaleArray={setPromoScaleArray}
                        checkedMaxBonus={checkedMaxBonus}
                        setCheckedMaxBonus={setCheckedMaxBonus}
                      />
                    )}
                    {promoType === DISCOUNT && (
                      <DiscountPromoRuleSection
                        cloningPromo={cloningPromo}
                        scaleArray={scaleArray}
                        setScaleArray={setScaleArray}
                      />
                    )}

                    <DateAndDescriptionSection
                      cloningPromo={cloningPromo}
                      promoType={promoType}
                      promoScaleArray={promoScaleArray}
                      scaleArray={scaleArray}
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                      description={description}
                      setDescription={setDescription}
                    />
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreatePromo

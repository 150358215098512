import React, { useContext, useEffect, useState, useRef } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import ReactFilter from '../components/inputs/ReactFilterList'
import ReactRadioFilter from '../components/inputs/ReactRadioFilter'
import ReactMultipleFilter from '../components/inputs/ReactMultipleFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilter,
  faExclamationCircle,
  faCalendar,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import Pagination from '../layout/Pagination'
import DatePicker from 'react-multi-date-picker'
import PosDownloadContext from './context/PosDownloadContext'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import moment from 'moment'
import { TASK_TYPES, TASK_TYPES_KEYS } from './constants/constants'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function PosDownloadView(props) {
  const {
    fetchDataStatus,
    clientsData,
    usersData,
    surveysData,
    productTypeDic,
    filterData,
    reportsData,
    pagination,
    fetchReportsFromServer,
    handlePaginationClick,
    handleDeleteReport,
    fetchSurveysFromServer,
    taskType,
    setTaskType,
    title,
    isFetching,
    failedCreation,
    setFailedCreation,
    failedCreationMessage,
    setFailedCreationMessage,
    filtersDic
  } = useContext(PosDownloadContext)

  const jobStatusDic = {
    pending: 'En proceso...',
    created: 'Completado',
    error: 'Error',
    expired: 'Expirado',
    empty: 'Reporte sin datos disponibles'
  }
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [resetTaskType, setResetTaskType] = useState(false)
  const [allSelectedOptions, setAllSelectedOptions] = useState(null)
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [collapsed, setCollapsed] = useState(props.collapsed)
  const [selectedDates, setSelectedDates] = useState(null)
  const [dateArray, setDateArray] = useState(null)
  const [dateQuery, setDateQuery] = useState(null)
  const [optionalReportTitle, setOptionalReportTitle] = useState('')
  const [warning, setWarning] = useState(false)
  const [didFiltersChange, setDidFiltersChanged] = useState(true)
  const [usersDisabled, setUsersDisabled] = useState(false)
  const [dateRangeDisabled, setDateRangeDisabled] = useState(false)
  const [surveysDisabled, setSurveysDisabled] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const [filters, setFilters] = useState(new Map())
  const [surveyFilters, setSurveyFilters] = useState(new Map())

  const filtersDisabled = !filterData || filterData.length === 0

  useEffect(() => {
    setDidFiltersChanged(true)
  }, [allSelectedOptions, dateArray, selectedSurvey])

  // Determines disabled behaviour for survey reports
  useEffect(() => {
    if (taskType == TASK_TYPES.survey.key) {
      if (allSelectedOptions) {
        let clientOptions = allSelectedOptions.get('Cliente') || []
        let userOptions = allSelectedOptions.get('Usuario') || []
        if (clientOptions.length > 0 && userOptions.length > 0 && dateArray != null) {
          setUsersDisabled(false)
          setDateRangeDisabled(false)
          fetchSurveysFromServer(allSelectedOptions, dateQuery)
        } else if (clientOptions.length > 0 && userOptions.length > 0) {
          setUsersDisabled(false)
          setDateRangeDisabled(false)
          setSurveysDisabled(true)
          setSelectedSurvey(null)
        } else if (clientOptions.length > 0) {
          setUsersDisabled(false)
          setDateRangeDisabled(true)
          setSurveysDisabled(true)
          setDateArray(null)
          setSelectedDates(null)
          setSelectedSurvey(null)
        } else {
          setUsersDisabled(true)
          setDateRangeDisabled(true)
          setSurveysDisabled(true)
          setDateArray(null)
          setSelectedDates(null)
          setSelectedSurvey(null)
          setAllSelectedOptions(null)
        }
      } else {
        setUsersDisabled(true)
        setDateRangeDisabled(true)
        setSurveysDisabled(true)
      }
    }
  }, [allSelectedOptions, dateArray])

  useEffect(() => {
    let start = moment.unix(dateArray?.Start)
    let end = moment.unix(dateArray?.End)
    if (dateArray?.Start > 0 && dateArray?.End) {
      if (limitDateRange(start, end)) {
        setFailedCreation(true)
        setFailedCreationMessage('Error: Solo puedes seleccionar un rango máximo de 31 días.')
        setTimeout(() => setFailedCreation(false), 15000)
      } else {
        setFailedCreation(false)
      }
    }
  }, [dateArray, dateQuery])

  // Resets all filters except taskType when taskType is changed
  useEffect(() => {
    setAllSelectedOptions(null)
    setOptionalReportTitle('')
    setDateArray(null)
    setSelectedDates(null)
    setSelectedSurvey(null)
    setResetReactFilters(!resetReactFilters)
    if (taskType == TASK_TYPES.survey.key) {
      setUsersDisabled(true)
      setDateRangeDisabled(true)
      setSurveysDisabled(true)
    } else {
      setUsersDisabled(false)
      setDateRangeDisabled(false)
      setSurveysDisabled(false)
    }
  }, [taskType])

  useEffect(() => {
    handleFilterData()
  }, [filterData])

  useEffect(() => {
    handleSurveysData()
  }, [surveysData])

  useEffect(() => {
    setSurveysDisabled(false)
  }, [surveyFilters])

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  const limitDateRange = (start, end) => {
    return Math.abs(start.diff(end, 'days')) + 1 > 31
  }

  const handleSelectionChange = (identifier, selectedOptions) => {
    const selectedOptionsArray = Array.from(selectedOptions)
    setAllSelectedOptions((prevOptions) => {
      const newOptions = new Map(prevOptions)
      newOptions.set(identifier, selectedOptionsArray)
      return newOptions
    })
  }

  const handleFilterArray = (key, value) => {
    let array = []
    value.forEach((v) => {
      array.push(filterData[filtersDic[key]].find((f) => v.includes(f.uid)).uid)
    })
    return array
  }

  const handleProductFilterArray = (key, value) => {
    let array = []
    value.forEach((v) => {
      array.push(filterData[filtersDic[key]].find((f) => v.includes(f.db_ref)).db_ref)
    })
    return array
  }

  const buildReportQuery = (event) => {
    event.preventDefault()
    setButtonDisabled(true)
    if (filterData) {
      let reportName = ''
      let taskAchieved = []
      let clientArray = []
      let userArray = []
      let productArray = []
      let brandArray = []
      let categoryArray = []
      let surveyArray = []
      let surveyDescription = ''

      if (allSelectedOptions) {
        for (const [key, value] of allSelectedOptions) {
          if (value.length != 0) {
            if (key == 'Cliente') {
              clientArray = handleFilterArray(key, value)
            } else if (key == 'Usuario') {
              userArray = handleFilterArray(key, value)
            } else if (key == 'Producto individual') {
              productArray = handleProductFilterArray(key, value)
            } else if (key == 'Marcas') {
              brandArray = handleProductFilterArray(key, value)
            } else if (key == 'Categoría') {
              categoryArray = handleProductFilterArray(key, value)
            } else if (key == 'Cumple Tarea') {
              value.forEach((t) => {
                taskAchieved.push(t)
              })
            }
          }
        }
        if (taskType == TASK_TYPES.survey.key) {
          let survey = surveysData.find((s) => s.uid.includes(selectedSurvey.substring(0, 9)))
          surveyArray.push(survey.uid)
          surveyDescription = survey.name
        }
      }
      reportName = handleReportName()
      if (dateArray == null || (taskType == TASK_TYPES.survey.key && surveyArray.length <= 0)) {
        setFailedCreation(true)
        setFailedCreationMessage(
          'Por favor, complete los campos obligatorios marcados con * para continuar.'
        )
        setButtonDisabled(false)
        setTimeout(() => setFailedCreation(false), 15000)
      } else if (limitDateRange(moment.unix(dateArray?.Start), moment.unix(dateArray?.End))) {
        setFailedCreation(true)
        setFailedCreationMessage('Error: Solo puedes seleccionar un rango máximo de 31 días.')
        setButtonDisabled(false)
        setTimeout(() => setFailedCreation(false), 15000)
      } else if (!didFiltersChange) {
        setFailedCreation(true)
        setFailedCreationMessage(
          'Error: El reporte ya no se encuentra disponible para su descarga. Por favor, genere un nuevo reporte.'
        )
        setButtonDisabled(false)
        setTimeout(() => setFailedCreation(false), 15000)
      } else {
        const body = {
          business_uid: cookie.load('business_tenant_uid'),
          task_type: taskType,
          clients_db_refs: clientArray,
          users_db_refs: userArray,
          start_time: dateArray['Start'],
          end_time: dateArray['End'],
          product_db_refs: productArray,
          brand_db_refs: brandArray,
          category_db_refs: categoryArray,
          task_achieved: taskAchieved,
          task_uuid: surveyArray,
          task_description: surveyDescription,
          created_date: new Date(),
          report_name: reportName
        }
        createNewReport(body)
      }
    }
  }

  const createNewReport = (body) => {
    fetch(`${BACKEND_URL}/pos_downloads`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (response) => {
    // evaluate responses for status 422 or 500
    setButtonDisabled(false)
    if (response.status === 422) {
      setFailedCreation(true)
      let obj = response.json()
      setFailedCreationMessage(Object.keys(obj.data).map((k) => obj.data[k]))
      setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.status === 500) {
      setFailedCreation(true)
      setFailedCreationMessage('Error al crear nuevo reporte. Por favor, edita los parametros.')
      setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.status === 404) {
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en creacion de reportes, verifique la información.')
      setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.status === 204) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Error: No es posible generar el reporte. No se encontraron resultados con los filtros seleccionados. Por favor, ajusta los filtros y vuelve a intentarlo para poder generar el reporte.'
      )
      setTimeout(() => setFailedCreation(false), 15000)
    } else if (response.ok) {
      fetchReportsFromServer()
      setDidFiltersChanged(false)
    } else {
      console.log('error: ', response)
    }
  }

  const fetchFindReport = async (model) => {
    let modelDate = new Date(model.due_date)
    if (modelDate <= new Date()) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Error: El reporte ya no se encuentra disponible para su descarga. Por favor, genere un nuevo reporte.'
      )
      return setTimeout(() => setFailedCreation(false), 15000)
    }
    if (model != null) {
      try {
        const response = await fetch(`${BACKEND_URL}/pos_downloads/${model.id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
        if (response.ok) {
          const data = await response.json()
          if (model.status == 'empty') {
            setWarning(true)
            setTimeout(() => setWarning(false), 15000)
          }
          window.open(data.pos_download_reports[0].report_url)
        }
      } catch (error) {
        console.error('Error finding report: ', error)
        setFailedCreation(true)
        setFailedCreationMessage('Error: No se puede obtener el reporte.')
        setTimeout(() => setFailedCreation(false), 15000)
      }
    }
  }

  const handleReportName = () => {
    let name = 'Analisis_PDV_' + TASK_TYPES[taskType].downloadName
    if (optionalReportTitle.length > 0) {
      name = optionalReportTitle
    }
    return `${name}_${moment().format('DD-MM-YYYY_HHmmss')}.csv`
  }

  const handleFilterData = async () => {
    setFilters([
      { key: 'Cliente', value: clientsData.map((c) => c['uid'] + ' - ' + c['name']) },
      { key: 'Usuario', value: usersData.map((u) => u['uid'] + ' - ' + u['name']) }
    ])
  }

  const handleSurveysData = async () => {
    setSurveyFilters([{ key: 'Encuesta', value: [] }])
    if (surveyFilters.length > 0) {
      setSurveyFilters([
        {
          key: 'Encuesta',
          value: surveysData.map((c) => c.uid.slice(9, 18) + ' - ' + c.name)
        }
      ])
    }
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()
    if (!datesObj || datesObj.length == 0) {
      setDateArray(null)
      setDateQuery(null)
    } else if (datesObj) {
      datesObj.forEach((date) => {
        formatedDates.push(date.unix)
      })
      //When there is a date range
      if (formatedDates.length > 1) {
        startDate = Math.min(formatedDates[0], formatedDates[1])
        endDate = Math.max(formatedDates[0], formatedDates[1])
      }
      //When is only one date
      else if (formatedDates.length == 1) {
        startDate = formatedDates[0]
        endDate = formatedDates[0]
      }
      queryParam = `&start=${moment.unix(startDate).startOf('day').unix()}&end=${moment
        .unix(endDate)
        .endOf('day')
        .unix()}`
      setDateQuery(queryParam)
      setDateArray({
        Start: moment.unix(startDate).startOf('day').unix(),
        End: moment.unix(endDate).endOf('day').unix()
      })
    }
  }

  const resetFilters = () => {
    setResetTaskType(!resetTaskType)
    setOptionalReportTitle('')
    setDateArray(null)
    setAllSelectedOptions(null)
    setSelectedDates(null)
    setSelectedSurvey(null)
    setUsersDisabled(false)
    setDateRangeDisabled(false)
    setSurveysDisabled(false)
    setResetReactFilters(!resetReactFilters)
  }

  const getTaskTypeKeyByValue = (value) => {
    return TASK_TYPES_KEYS.find((key) => TASK_TYPES[key].name === value)
  }

  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong>{failedCreationMessage}</strong>
        </div>
      )}
      {warning === true && (
        <div className="alert alert-warning" role="alert">
          <strong>
            Advertencia: El reporte que ha descargado no contiene datos. Por favor, revise los
            filtros aplicados o intente generar el reporte nuevamente.
          </strong>
        </div>
      )}
      <div className="index-header detailed-container">
        <h2 className="mb-20 float-unset">{title}</h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          <h6 style={{ fontSize: 14 }}>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: 0 }} />
            <b>Nota:</b> Personalice y descargue reportes en formato CSV o XSLS. Ajuste los filtros
            según la configuración previa para obtener datos específicos.
          </h6>
          <h4 className="filter-by-text">Descargar tareas por:</h4>
          <div className="filters-search-container mb-10">
            <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
            <div className="filters-container">
              <div key={`mul-flr-task-types`} className="mr-10">
                <ReactRadioFilter
                  className="mr-10"
                  title={'Tipo de Tarea'}
                  optionsKeys={Object.keys(TASK_TYPES).map((type) => TASK_TYPES[type].name)}
                  resetWatcher={resetTaskType}
                  isRequired={true}
                  onChange={(newTaskType) => {
                    setTaskType(getTaskTypeKeyByValue(newTaskType))
                  }}
                />
              </div>
              {taskType != null && (
                <>
                  {taskType != TASK_TYPES.client_application.key &&
                    Array.from(filters).map((filter, indx) => (
                      <div key={`flr-${indx}`} className="mr-10">
                        <ReactFilter
                          className="mr-10"
                          title={filter.key}
                          identifier={filter.key}
                          options={filter.value}
                          resetWatcher={
                            (filter.key == 'Usuario' && usersDisabled) || resetReactFilters
                          }
                          isRequired={taskType == TASK_TYPES.survey.key}
                          onSelectionChange={handleSelectionChange}
                          disabled={filtersDisabled || (filter.key == 'Usuario' && usersDisabled)}
                          selectAllRequired={true}
                        />
                      </div>
                    ))}
                  {taskType == TASK_TYPES.client_application.key && (
                    <div key={`flr-users`} className="mr-10">
                      <ReactFilter
                        className="mr-10"
                        title={'Usuario'}
                        identifier={'Usuario'}
                        options={filters[1].value}
                        resetWatcher={resetReactFilters}
                        onSelectionChange={handleSelectionChange}
                        disabled={filtersDisabled}
                        selectAllRequired={true}
                      />
                    </div>
                  )}
                  {(taskType == TASK_TYPES.inventory.key ||
                    taskType == TASK_TYPES.planimetry.key) && (
                      <div key={`mul-flr-products`} className="mr-10">
                        <ReactMultipleFilter
                          className="mr-10"
                          title={'Producto'}
                          optionsKeys={['Marcas', 'Categoría', 'Producto individual']}
                          optionsDic={productTypeDic}
                          resetWatcher={resetReactFilters}
                          allSelectedOptions={allSelectedOptions}
                          disabled={filtersDisabled}
                          selectAllRequired={true}
                          onChange={(newAllSelectedOptions) => {
                            setAllSelectedOptions(newAllSelectedOptions)
                          }}
                        />
                      </div>
                  )}
                  {taskType == TASK_TYPES.planimetry.key && (
                    <div key={`flr-cumple-tarea`} className="mr-10">
                      <ReactFilter
                        className="mr-10"
                        title={'Cumple Tarea'}
                        identifier={'Cumple Tarea'}
                        options={['Si cumple', 'No cumple']}
                        resetWatcher={resetReactFilters}
                        onSelectionChange={handleSelectionChange}
                        disabled={filtersDisabled}
                      />
                    </div>
                  )}
                  <DatePicker
                    disabled={filtersDisabled || dateRangeDisabled}
                    range
                    rangeHover
                    dateSeparator=" - "
                    months={[
                      'Enero',
                      'Febrero',
                      'Marzo',
                      'Abril',
                      'Mayo',
                      'Junio',
                      'Julio',
                      'Agosto',
                      'Septiembre',
                      'Octubre',
                      'Noviembre',
                      'Diciembre'
                    ]}
                    weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                    format="DD MMM"
                    weekStartDayIndex={1}
                    highlightToday={false}
                    numberOfMonths={2}
                    maxDate={moment().local().endOf('day').toDate()}
                    value={selectedDates}
                    onChange={(dateObjects) => {
                      handleDateFilter(dateObjects)
                      setSelectedDates(dateObjects)
                    }}
                    plugins={[
                      <Toolbar
                        position="bottom"
                        names={{
                          deselect: 'Deseleccionar'
                        }}
                        sort={['deselect']}
                      />
                    ]}
                    render={(value, openCalendar) => {
                      return (
                        <div
                          key={`flr-date`}
                          className={`mr-10 ${
                            filtersDisabled || dateRangeDisabled ? 'react-filter-disabled' : ''
                          }`}
                        >
                          <div className="react-filter-box filter-input-width-datepicker">
                            <div
                              title={'Rango de fechas'}
                              onClick={
                                !filtersDisabled || !dateRangeDisabled ? openCalendar : undefined
                              }
                              className="react-filter-box-clickable-zone"
                            >
                              <div className="react-filter-titles">
                                {value?.length > 0 && (
                                  <>
                                    {Array.isArray(value) ? (
                                      <div className="filter-title-overflow">
                                        {value.join(' - ')}
                                      </div>
                                    ) : (
                                      <div className="filter-title-overflow">{value}</div>
                                    )}
                                    <FontAwesomeIcon
                                      style={{ color: 'ef823a' }}
                                      icon={faCalendar}
                                    />
                                  </>
                                )}
                                {value?.length == 0 && (
                                  <>
                                    <div className="filter-title-overflow">
                                      RANGO DE FECHAS <span className="red">*</span>
                                    </div>
                                    <FontAwesomeIcon
                                      style={{
                                        color:
                                          filtersDisabled || dateRangeDisabled
                                            ? '#D5D5D5'
                                            : '#597fa9'
                                      }}
                                      icon={faCalendar}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                  {taskType == TASK_TYPES.survey.key && (
                    <div key={`flr-encuesta`} className="mr-10">
                      <ReactRadioFilter
                        className="mr-10"
                        title={'Encuesta'}
                        optionsKeys={surveyFilters.length != 0 ? surveyFilters[0].value : []}
                        resetWatcher={resetReactFilters || surveysDisabled}
                        isRequired={true}
                        disabled={filtersDisabled || surveysDisabled || surveyFilters.length == 0}
                        onChange={(selectedSurvey) => {
                          setSelectedSurvey(selectedSurvey)
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {taskType != null && (
              <div className="generate-report-container">
                <button
                  type="button"
                  className={`create-report-btn ${buttonDisabled ? 'report-btn-disabled' : ''}`}
                  onClick={buildReportQuery.bind(this)}
                  disabled={buttonDisabled}
                >
                  Generar Reporte
                </button>
              </div>
            )}
          </div>
          {taskType != null && (
            <>
              <div>
                <input
                  value={optionalReportTitle}
                  onChange={(e) => {
                    setOptionalReportTitle(e.target.value)
                  }}
                  className="report-name-input"
                  placeholder="Título de reporte opcional"
                />
              </div>
              <div className="mb-20">
                <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                  Restablecer filtros aplicados
                </a>
              </div>
            </>
          )}
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">NOMBRE DEL REPORTE</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">ESTATUS</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">TIPO DE TAREA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">RANGO DE FECHAS</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <span className="th-header-text">ACCIÓN</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isFetching &&
                  reportsData?.map((report, indx) => (
                    <tr key={indx}>
                      <td className="td-text-limited">{report.name}</td>
                      <td className="td-text-limited">{jobStatusDic[report.status]}</td>
                      <td className="td-text-limited">
                        {TASK_TYPES[report.task_type].description.toUpperCase()}
                      </td>
                      <td className="td-text-limited">
                        {moment(report.start_date).format('DD-MM-YYYY')} -{' '}
                        {moment(report.end_date).format('DD-MM-YYYY')}
                      </td>
                      <td style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                          type="button"
                          className={`download-report-btn ${
                            report.status != 'created' && report.status != 'empty'
                              ? 'report-btn-disabled'
                              : ''
                          }`}
                          disabled={report.status != 'created' && report.status != 'empty'}
                          onClick={async () => {
                            await fetchFindReport(report)
                          }}
                        >
                          Descargar Reporte
                        </button>
                        <button
                          type="button"
                          className={`delete-report-btn ${
                            report.status == 'pending' ? 'delete-report-disabled' : ''
                          }`}
                          onClick={(e) => {
                            handleDeleteReport(report, e)
                          }}
                          disabled={report.status == 'pending'}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} style={{ margin: 0 }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                {!isFetching && reportsData?.length === 0 && (
                  <tr>
                    <td>
                      No hay reportes generados, una vez generados estarán disponibles por un
                      período de 24 horas.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pagination.total_pages > 1 && (
              <div onClick={handlePaginationClick}>
                <Pagination
                  current_page={pagination.current_page}
                  total_pages={pagination.total_pages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
